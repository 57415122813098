import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledDateTimePicker } from '@components/inputs/ControlledDateTimePicker';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { isUserDisabled } from '@features/backend-label/user-label.interface';
import { OrderQuote } from '@features/quote/OrderQuote';
import { RestrictRole } from '@features/auth/RestrictRole';
import { useDisableRole } from '@features/auth/use-disable-role';
import { UserRoles } from '@features/user/user-role.type';
import { OrderCancelledWarning } from '@features/order/shared/cancel-or-restore-order/OrderCancelledWarning';
import { CancelOrRestoreOrder } from '@features/order/shared/cancel-or-restore-order/CancelOrRestoreOrder';
import { SensitivePersonalInformation } from './sensitive-personal-information/SensitivePersonalInformation';
import { useSaveOrderForm } from '../use-save-order-form';
import { IOrderNeedsAssessment } from './order-needs-assessment.interface';
import { sanitize, serialize } from './form-config';
import { Appointments } from './appointment/Appointments';
import { Reports } from './report/Reports';

type Props = {
  order: IOrderNeedsAssessment;
};

export const OrderNeedsAssessment: React.FC<Props> = ({ order }) => {
  const {
    control,
    submitHandler,
    isButtonDisabled,
    isDropdownsLoading,
    operatorOptions,
    statusOptions,
    assessmentTypeOptions,
    assessmentCentreOptions,
    organisationOptions,
    qualityAssuranceOptions,
  } = useSaveOrderForm(order, sanitize, serialize);

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);

  return (
    <Box>
      <OrderCancelledWarning orderId={order.id} position="top" />
      <form onSubmit={submitHandler}>
        <Box>
          <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
            <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
              <Grid item xs={4} md={4} lg={4}>
                <ControlledAutocomplete
                  control={control}
                  name="userId"
                  label="Operator"
                  options={operatorOptions}
                  getOptionDisabled={isUserDisabled}
                  isLoading={isDropdownsLoading}
                />
              </Grid>
            </RestrictRole>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="orderStatusId"
                options={statusOptions}
                label="Order Status"
                isLoading={isDropdownsLoading}
                groupBy={(option): string => option.orderType}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="assessmentType"
                options={assessmentTypeOptions}
                label="Assessment Type"
                isLoading={isDropdownsLoading}
                disabled={isDisabledForAssessorAndQA}
              />
            </Grid>
            <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
              <Grid item xs={4} md={4} lg={4}>
                <ControlledAutocomplete
                  control={control}
                  name="assessmentCentreId"
                  options={assessmentCentreOptions}
                  label="Assessment Centre"
                  isLoading={isDropdownsLoading}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <ControlledDatePicker control={control} name="nextContactDate" label="Next Contact Due" disablePast />
              </Grid>
            </RestrictRole>
            <Grid item xs={4} md={4} lg={4}>
              <ControlledAutocomplete
                control={control}
                name="organisationId"
                options={organisationOptions}
                label="Organisation"
                isLoading={isDropdownsLoading}
                disabled={isDisabledForAssessorAndQA}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <SensitivePersonalInformation order={order} />
            </Grid>
          </Grid>
        </Box>
        <Appointments orderId={order.id} endDate="2025-01-01" isDisabled={false} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
            Needs Assessment Report Details
          </Typography>
          <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
            <Grid item xs={4} md={4} lg={3}>
              <ControlledToggleButton
                fullWidth
                control={control}
                name="isReviewRequested"
                getLabel={(value: boolean): string =>
                  value ? 'Student Review Requested' : 'Student Review Not Requested'
                }
              />
            </Grid>
            <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
              <Grid item xs={4} md={4} lg={3}>
                <ControlledDateTimePicker
                  control={control}
                  name="reportSentDate"
                  label="Date report sent to Student"
                  disablePast
                  disabled={!order.isReviewRequested}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={3}>
                <ControlledAutocomplete
                  control={control}
                  name="qualityAssuranceUserId"
                  label="QA Person"
                  options={qualityAssuranceOptions}
                  getOptionDisabled={isUserDisabled}
                  isLoading={isDropdownsLoading}
                />
              </Grid>
              <Grid item xs={4} md={4} lg={3}>
                <ControlledDateTimePicker
                  control={control}
                  name="submissionDate"
                  label="SLC NAR Submission Date and Time"
                  disablePast
                />
              </Grid>
            </RestrictRole>
            <Grid item xs={4} md={4} lg={3}>
              <ControlledToggleButton
                fullWidth
                control={control}
                name="shareNarWithHep"
                getLabel={(value: boolean): string => (value ? 'Share NAR with HEP' : 'Do Not Share NAR with HEP')}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={3}>
              <ControlledTextField control={control} name="quoteId" label="Quote ID" />
            </Grid>
            <Grid item xs={4} md={4} lg={3}>
              <OrderQuote orderId={order.id} quoteId={order.quoteId} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Reports order={order} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <ControlledTextField
            control={control}
            name="notes"
            label="Notes"
            multiline
            minRows={3}
            fullWidth
            disabled={isDisabledForAssessorAndQA}
          />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <CancelOrRestoreOrder orderId={order.id} />
          <Button sx={{ flexGrow: 1 }} variant="contained" size="large" type="submit" disabled={isButtonDisabled}>
            Save Order
          </Button>
        </Stack>
      </form>
      <OrderCancelledWarning orderId={order.id} position="bottom" />
    </Box>
  );
};
