import { useCallback } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { IOrder } from './order.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { collectChangedValues } from '@utils/collect-changed-values';
import { TransportableModel } from '@models/transportable.type';
import { useCreateOrderMutation, useSaveOrderMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags, mergeRequestStatusFlags } from '@utils/merge-request-status-flags';
import { errorHandlerFactory } from '@api/error-handler.factory';
import { useAppDispatch } from '@store/use-app-dispatch';
import { OrdersActions } from './orders.slice';
import { RouteParamsType } from '@routes/route-params.type';
import { CustomersActions } from '@features/customer/customer.slice';
import { useIsOrderDisabled } from './shared/cancel-or-restore-order/use-is-order-disabled';

type HookResult<FormModel extends FieldValues> = {
  submitHandler: React.FormEventHandler;
  isButtonDisabled: boolean;
  isDropdownsLoading: boolean;
} & UseFormReturn<FormModel> &
  AppBackendLabels &
  RequestStatusFlags;

export function useSaveOrderForm<
  FormModel extends FieldValues,
  ApiModel extends TransportableModel<ApiModel>,
  Order extends IOrder,
>(
  order: Order,
  sanitize: (model: Order, backendLabels: AppBackendLabels) => FormModel,
  serialize: (model: FormModel) => ApiModel,
  onClose?: () => void,
): HookResult<FormModel> {
  const { isLoading, ...backendLabels } = useAppBackendLabels([
    'assessmentCentreOptions',
    'assessmentTypeOptions',
    'intensityOptions',
    'levelOfStudyOptions',
    'organisationOptions',
    'statusOptions',
    'typeOptions',
    'userOptions',
    'warrantyOptions',
    'qualityAssuranceOptions',
  ]);
  const values = sanitize(order, backendLabels);
  const useFormResult = useForm<FormModel>({ values });
  const { handleSubmit, formState, setError, reset } = useFormResult;
  const [createMutation, createFlags] = useCreateOrderMutation();
  const [saveMutation, saveFlags] = useSaveOrderMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDisabled: isOrderCancelled } = useIsOrderDisabled(order.id);
  const { customerIdString } = useParams<RouteParamsType>();
  const studentId = parseInt(customerIdString as unknown as string, 10);

  const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;

  const isSaveEnabled = hasDirtyFields && !formState.isLoading && !formState.isSubmitting && !isLoading;
  const isButtonDisabled = !isSaveEnabled || isOrderCancelled;

  const typeId = order.orderTypeId;
  const statusOptions = backendLabels.statusOptions.filter(({ orderTypeId }) => typeId && orderTypeId === typeId);
  const organisationOptions = backendLabels.organisationOptions.filter((option) =>
    option.orderTypesIds.includes(typeId as number),
  );

  const errorHandler = errorHandlerFactory<FormModel>(setError);

  const mutation = order.id ? saveMutation : createMutation;
  const handleFormSubmit = useCallback(
    async (data: FormModel): Promise<void> => {
      const serializedData = serialize(data);
      const payload = { ...collectChangedValues(serializedData, values), studentId };
      const result = await mutation({
        id: order.id ?? null,
        payload,
      }).unwrap();
      if (isErrorResponse(result)) {
        errorHandler(result);
        return;
      }

      dispatch(OrdersActions.upsertOrder(result));

      // TODO_REF on creating new order we update customer order tabs
      if (!order.id) {
        dispatch(
          CustomersActions.updateCustomerOrderTabs({
            id: result.id,
            orderTypeId: result.orderTypeId,
            orderTypeName: result.orderClass, // TODO WTF Mate?
          }),
        );
      }
      navigate(`${Routes.orderSummaries}/${result.customerId}/${result.id}`, { replace: true });
      onClose && onClose();
      reset(sanitize(result as Order, backendLabels));
    },
    [
      serialize,
      values,
      mutation,
      order.id,
      studentId,
      dispatch,
      navigate,
      onClose,
      reset,
      sanitize,
      backendLabels,
      errorHandler,
    ],
  );

  const submitHandler = handleSubmit(handleFormSubmit);

  return {
    ...backendLabels,
    ...useFormResult,
    ...mergeRequestStatusFlags(createFlags, saveFlags),
    submitHandler,
    statusOptions,
    organisationOptions,
    isButtonDisabled,
    isDropdownsLoading: isLoading,
  };
}
