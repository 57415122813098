import React from 'react';
import { useOrderTypeStatusSummaries } from './use-order-type-status-summaries';
import { Grid } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { StatusTable } from './StatusTable';
import { OrderTypeTable } from './OrderTypeTable';
import { useDisableRole } from '@features/auth/use-disable-role';
import { UserRoles } from '@features/user/user-role.type';

export const DashboardPage: React.FC = () => {
  const { summaries, isLoading } = useOrderTypeStatusSummaries();

  const isDisabledForQA = useDisableRole([UserRoles.qualityAssurance]);

  useAppTitle('Dashboard');

  return (
    <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12, xl: 12 }}>
      {!isDisabledForQA && (
        <Grid item xs={4} md={8} lg={12} xl={6}>
          <OrderTypeTable />
        </Grid>
      )}
      {summaries.map((summary) => (
        <Grid item xs={4} md={8} lg={12} xl={6} key={`status-${summary.type}`}>
          <StatusTable isLoading={isLoading} {...summary} />
        </Grid>
      ))}
    </Grid>
  );
};
